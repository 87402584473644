// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
@font-face {
  font-family: 'Avenir';
  src: url('../fonts/AvenirLTStd-Book.otf');
}

nav {
  height: 7rem !important;
  background-color: #fff;
}

.topnav {
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-bottom: solid 0.5rem $brand-color;
}

.Navi {
  padding-bottom: 0px;
}

.topnav a {
  float: left;
  display: block;
  color: #646363;
  text-align: center;
  padding: 4px 5px;
  text-decoration: none;
  font-size: 15px;
  //text-transform: capitalize;
  font-family: Avenir;
}

.topnav a:hover {
  color: $brand-color;
  text-decoration: none;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}

@media screen and (max-width: 600px) {
  .dropdown {
    display: none;
  }
}

.dropdown {
  float: left;
}

.dropbtn {
  font-size: 15px;
  text-transform: capitalize;
  font-family: Avenir;
  border: none;
  background: none;
  color: #646363;
  display: flex;
  padding: 4px 5px;
}

.dropdown {
  outline: none;
  color: #646363;
  padding: 30px 16px;
  background-color: #fff;
  z-index: 2000;
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: #fff;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 5.7rem;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000000;
  padding: 0.5rem !important;
}

.dropdown-content a {
  float: none;
  color: #646363;
  padding: 15px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropbtn:hover {
  color: $brand-color;
}

.dropdown-content a:hover {
  background-color: #fff;
}

.dropdown:hover {
  background-clip: content-box;
}

.dropdown-content::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  width: 150%;
  height: 120%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.dropdown:hover .dropdown-content {
  display: block;
  background-clip: padding-box;
}

.hero {
  border-bottom: solid 0.5rem $brand-color;
  height: 80vh !important;
}
