// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
* {
  font-family: 'Raleway' !important;
  box-sizing: inherit;
}

.homepage {
  .flex {
    a {
      color: $text-color;
    }
  }

  .intro {
    .intro-description {
      padding-left: 3.125rem;
      padding-top: 9rem;
      padding-bottom: 10rem;
      border-bottom: solid 0.5rem $brand-color;
    }

    h1 {
      color: $brand-color;
      background-color: #fff;
      padding: 0.5rem;
    }
  }

  .secondintro {
    text-align: center;
    margin: 4rem 0 0 0;

    h1 {
      color: $brand-color;
    }
  }
}

.nav-link {
  color: $background-color;
}

.uk-section {
  display: inherit;
}

@media screen and (max-width: 800px) {
  .homepage {
    .intro {
      margin-top: -8.1%;
    }

    .stack-top {
      top: 0.5rem;
      width: 100% !important;

      h1 {
        font-size: 22px;
      }
    }
  }
}

.stack-top {
  z-index: 9;
  margin-right: 20px;
  margin-left: 20px;
  position: absolute;
  width: 50% !important;
  top: 80px;

  h1 {
    font-family: 'Avenir';
    color: $brand-color;
  }
}

.containerintro {
  position: relative;
  width: 100% !important;
}

// .carouselintro {
//   // filter: brightness(0.7);

//   // img {
//   //   height: 100%;
//   // }
// }
