// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.button {
  display: inline-block;
  border: none;
  color: #fff;
  text-decoration: none;
  background-color: $accent !important;
  border-color: $accent !important;
  padding: 15px 45px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 3px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  outline: none;
  transition: background-color 0.1s ease;
  cursor: pointer;
  &::after {
    transition: background-color 0.2s ease;
    position: absolute;
    content: '';
    height: 4px;
    bottom: 0;
    width: 100%;
    background-color: darken($accent, 50%);
    opacity: 0.18;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    left: 0;
  }
  &:focus,
  &:hover {
    text-decoration: none;
    color: #fff;
  }
  &:hover {
    background-color: lighten($accent, 5%) !important;
    &::after {
      opacity: 0.22;
    }
  }
  &:active {
    background-color: darken($accent, 5%) !important;
    &::after {
      opacity: 0.32 !important;
    }
  }
}
.button-toggle {
  @extend .button;
  text-transform: inherit;
}
.buttonlan {
  background: transparent;
  border: none;
  padding: 0px;
  margin: 0px;
  display: none;
}
@media screen and (min-width: 700px) {
  .buttonlan {
    display: inline;
  }
}
