// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footer {
  background-color: $brand-color;
  padding: 1rem;
  border-top-left-radius: 50px;
  height: 100%;
  width: 100%;

  a {
    color: #fff;
  }
}

.copyright {
  color: $light-grey;
  font-size: 0.7em;
}
