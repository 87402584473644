// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color; 
  text-decoration: none !important;

  @include on-event {
    color: $accent;
    text-decoration: underline;
  }
}

.col-md {
  width: 250px !important;
}
.image-size-valori {
  width: 400px !important;
  height: 400px;
}

.card-image-size-full {
  width: 100%;
  margin-top: -15%;
}
.card-image-size {
  width: 450px !important;
  height: 300px !important;
}
// @media(max-width: 1300px){
//   .card-image-size{
//     width: 250px !important;
//   }
// }
