// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
// Small tablets and large smartphones (landscape view)
@media (min-width: 320px) {
  .inside-domains-page,
  .inside-page {
    margin: 50px 200px;
    white-space: normal;
  }
}

@media (min-width: 320px) {
  .inside-domains-page {
    margin: 20px;
    white-space: normal;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.slider-control-centerleft,
.slider-control-centerright {
  display: none;
}
