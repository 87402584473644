.tab-navigation {
  width: 100%;
  margin: 0 auto;

  .tab-header {
    display: flex;
    justify-content: space-around;
    background-color: #eee;
    padding: 10px;

    button {
      padding: 10px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      font-size: 16px;

      &.active {
        font-weight: bold;
        color: #333;
        border-bottom: 2px solid #333;
      }

      &:hover {
        color: #555;
      }
    }
  }

  .tab-content {
    padding: 20px;
    border: 1px solid #ddd;
    border-top: none;
  }
}
