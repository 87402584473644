.hero {
  height: 60vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: position 50%;
  background-size: cover;

  h1 {
    color: #fff;
    font-weight: 900;
    font-family: 'Gilroy', sans-serif;
  }
}
.hero-gestionare {
  border-bottom: solid 0.5rem $brand-color;
  height: 80vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.herocariera {
  @extend .hero;
  background-image: url('../../assets/images/cariera/cariera.png');

  h1 {
    color: #fff;
  }
}

.hero-training {
  @extend .hero-gestionare;
  background-image: url('../../assets/images/tr.png');
}

.herostiri {
  @extend .hero;
  background-image: url('../../assets/images/stiri/stiri.jpg');
}
.herovalori {
  @extend .hero;
  background-image: url('../../assets/images/valori/valori.jpg');
}
.herogrupul {
  @extend .hero;
  background-image: url('../../assets/images/grupul-jba/map.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.herolegislativ {
  @extend .hero;
  background-image: url('../../assets/images/legislativ/3.3 G.jpg');
  background-size: cover;
}

.heromri {
  text-align: center;
  background-image: none;
  flex-direction: column;
  border: none;
  margin-top: 2rem;
  margin-bottom: none;

  h1 {
    color: $text-color;
    text-transform: uppercase;
  }
}

.uk-card-title {
  font: normal normal bold 22px/1.4em 'Avenir', sans-serif;
  line-height: normal;
  margin: 0 0 1 0rem;
  color: #949494;
  width: 650px !important;
}
.title-asociatii {
  @extend .uk-card-title;
  font-weight: normal;
  width: 100% !important;
}

.uk-body {
  font: normal normal normal 15px/1.4em 'Helvetica Neue', sans-serif;
  color: #3b3a3a;
  margin-bottom: 10px;
  width: 650px !important;
}
// @media(max-width: 1300px){
//   .uk-body{
//     width: 450px !important;
//   }
// }
.row-thing {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #fafafa;
  padding: 2rem 0;
  margin: 1rem;
}

// .coll-thing {
//   display: flex;
//   justify-content: center;
//   width: 100%;
// }

.card-thing {
  border: solid 1px $brand-color;
  border-radius: 0%;
  padding: 0.8rem;
  width: 17rem;
  height: 100;
  margin: 1rem;
}

.card-body-thing {
  padding: 0;

  p {
    font-size: 14px;
    font-family: Helvetica;
  }
}

.card-title-thing {
  padding: 0rem;

  h2 {
    color: rgb(112, 201, 213);
    font-size: 22px;
    font-family: 'Avenir';
  }
}

.row {
  margin: 2rem 0px;
}

.echipa-card-img {
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  height: 250px;
  width: 250px;
}
